import React, { useMemo } from "react";
import { StyleSheet, View } from "react-native";
import {
  Button,
  Appbar,
  Card,
  Text,
  Avatar,
  TextInput,
  useTheme
} from "react-native-paper";
import ScreenWrapper from "../ScreenWrapper";
import Slider from "@react-native-community/slider";
import { tokens } from "../utils/Constants";
import { MD3Colors } from "react-native-paper/src/types";

const { palette, opacity } = tokens;

export default function Home({ navigation }) {
  const styles = useStyles();
  const { colors } = useTheme();

  return (
    <ScreenWrapper>
      <View style={styles.container}>
        <Text variant="headlineLarge">Create diary entry</Text>
        <Text style={styles.text}>Log your feelings today</Text>
        <View style={styles.row}>
          <Text style={styles.label}>Positive/negative mood overall:</Text>
          <Slider
            style={{ width: 200, height: 40 }}
            minimumValue={1}
            maximumValue={5}
            minimumTrackTintColor={colors.primary}
            maximumTrackTintColor={colors.surfaceVariant}
            thumbTintColor={colors.primary}
          />
          <Text style={styles.label}>Stress:</Text>
          <Slider
            style={{ width: 200, height: 40 }}
            minimumValue={1}
            maximumValue={5}
            minimumTrackTintColor={colors.primary}
            maximumTrackTintColor={colors.surfaceVariant}
            thumbTintColor={colors.primary}
          />
          <Text style={styles.label}>Social:</Text>
          <Slider
            style={{ width: 200, height: 40 }}
            minimumValue={1}
            maximumValue={5}
            minimumTrackTintColor={colors.primary}
            maximumTrackTintColor={colors.surfaceVariant}
            thumbTintColor={colors.primary}
          />
          <Text style={styles.label}>Impulsive:</Text>
          <Slider
            style={{ width: 200, height: 40 }}
            minimumValue={1}
            maximumValue={5}
            minimumTrackTintColor={colors.primary}
            maximumTrackTintColor={colors.surfaceVariant}
            thumbTintColor={colors.primary}
          />
          <Text style={styles.label}>Concentration:</Text>
          <Slider
            style={{ width: 200, height: 40 }}
            minimumValue={1}
            maximumValue={5}
            minimumTrackTintColor={colors.primary}
            maximumTrackTintColor={colors.surfaceVariant}
            thumbTintColor={colors.primary}
          />
          <TextInput
            editable={true}
            multiline={true}
            style={styles.textbox}
            label="How do you feel today?"
          ></TextInput>
          <Button
            mode="contained-tonal"
            onPress={() => navigation.navigate("Profile")}
          >
            Save
          </Button>
        </View>
      </View>
    </ScreenWrapper>
  );
}

const getStyles = ({ colors }: { colors: MD3Colors }) => {
  return StyleSheet.create({
    textbox: {
      width: 300
    },
    container: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center"
    },
    row: {
      flexDirection: "column",
      paddingHorizontal: 12,
      paddingBottom: 12,
      rowGap: 12,
      alignItems: "center"
    },
    text: {
      margin: 12
    },
    label: {
      marginBottom: 0,
      color: colors.secondary
    }
  });
};

function useStyles() {
  const { colors } = useTheme();

  // We only want to recompute the stylesheet on changes in color.
  const styles = useMemo(() => getStyles({ colors }), [colors]);

  return styles;
}
