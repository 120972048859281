import * as React from "react";
import { useCallback } from "react";
import { Provider as PaperProvider, Text } from "react-native-paper";
import App from "./src/App";
import * as SplashScreen from "expo-splash-screen";
import { Appearance, useColorScheme } from "react-native";
import { lightTheme, darkTheme } from "./src/utils/Theme";
import { useFonts, YesevaOne_400Regular } from "@expo-google-fonts/yeseva-one";

export default function Main() {
  const colorScheme = useColorScheme();

  const [fontsLoaded] = useFonts({
    YesevaOne_400Regular
  });

  if (!fontsLoaded) {
    return null;
  }

  const combinedTheme = colorScheme === "dark" ? darkTheme : lightTheme;

  return (
    <PaperProvider theme={combinedTheme}>
      <App />
    </PaperProvider>
  );
}
