import React, { useMemo } from "react";
import { Surface, Text, Avatar, Card, Chip } from "react-native-paper";
import ScreenWrapper from "../ScreenWrapper";
import { StyleSheet, ScrollView, View } from "react-native";
import Icon from "react-native-vector-icons/MaterialIcons";
import FaIcon from "react-native-vector-icons/FontAwesome5";
import IIcon from "react-native-vector-icons/Ionicons";
import { HormoneIcon } from "../components/Tags";
import { useTheme } from "react-native-paper";
import { MD3Colors } from "react-native-paper/src/types";

type TraitSourceProps = { source: "genome" | "quiz" | "diary" };

const TraitSource = ({ source }: TraitSourceProps) => {
  const styles = useStyles();

  const icon = () => {
    switch (source) {
      case "genome":
        return <Icon name="science" size={20} style={styles.iconSource} />;
      case "diary":
        return <Icon name="auto-stories" size={20} style={styles.iconSource} />;
      case "quiz":
        return <Icon name="psychology" size={20} style={styles.iconSource} />;
    }
  };

  return (
    <Chip style={styles.chip} icon={icon}>
      {source}
    </Chip>
  );
};

const Profile = () => {
  const styles = useStyles();

  return (
    <ScreenWrapper>
      <Surface style={styles.surface} elevation={4}>
        <Avatar.Text size={80} label="NN" />
        <Text variant="headlineLarge">Nicole’s profile</Text>
      </Surface>
      <ScrollView
        style={[styles.container]}
        contentContainerStyle={styles.content}
      >
        <Card style={styles.card}>
          <Card.Title
            title="Agreeableness"
            titleVariant="headlineSmall"
            left={(props) => (
              <Avatar.Icon
                {...props}
                icon={() => (
                  <FaIcon name="hands-helping" size={22} style={styles.icon} />
                )}
              />
            )}
          />
          <Card.Content>
            <Text variant="bodyMedium" style={styles.paragraph}>
              A tendency towards pro-social behavior can lead an agreeable
              person to be perceived as warm, sociable and ‘likeable amongst his
              or her peers’. They are friendly towards other people, tolerant of
              others and show more patience than those who are less agreeable. A
              person who is agreeable may be more trusting of others and their
              intentions
            </Text>
            <View style={styles.row}>
              <Text variant="bodyMedium" style={styles.label}>
                Sources:
              </Text>
              <TraitSource source="genome" />
              <TraitSource source="quiz" />
            </View>
            <View style={styles.row}>
              <Text variant="bodyMedium" style={styles.label}>
                Hormones responsible:
              </Text>
              <HormoneIcon hormone={{ id: "oxytocin", imbalance: "high" }} />
              <HormoneIcon hormone={{ id: "oestrogen", imbalance: "high" }} />
            </View>
          </Card.Content>
        </Card>
        <Card style={styles.card}>
          <Card.Title
            title="Openness"
            titleVariant="headlineSmall"
            left={(props) => (
              <Avatar.Icon
                {...props}
                icon={() => (
                  <Icon name="lightbulb" size={28} style={styles.icon} />
                )}
              />
            )}
          />
          <Card.Content>
            <Text variant="bodyMedium" style={styles.paragraph}>
              Those who are considered “open to experience,” can generally be
              described as intellectually and artistically curious with a keen
              sense of beauty. Those who are Open to Experience excel in
              creative roles, and can be found in the upper echelons of academia
              and design teams. However, they tend to avoid positions that
              mandate adherence to a set of rules and guidelines.
            </Text>
            <View style={styles.row}>
              <Text variant="bodyMedium" style={styles.label}>
                Sources:
              </Text>
              <TraitSource source="genome" />
              <TraitSource source="quiz" />
            </View>
            <View style={styles.row}>
              <Text variant="bodyMedium" style={styles.label}>
                Hormones responsible:
              </Text>
              <HormoneIcon hormone={{ id: "oxytocin", imbalance: "high" }} />
              <HormoneIcon hormone={{ id: "dopamine", imbalance: "low" }} />
            </View>
          </Card.Content>
        </Card>
        <Card style={styles.card}>
          <Card.Title
            title="Extraversion"
            titleVariant="headlineSmall"
            left={(props) => (
              <Avatar.Icon
                {...props}
                icon={() => (
                  <Icon name="emoji-people" size={28} style={styles.icon} />
                )}
              />
            )}
          />
          <Card.Content>
            <Text variant="bodyMedium" style={styles.paragraph}>
              People who are low in this personality trait or introverted tend
              to be more reserved. They have less energy to expend in social
              settings and social events can feel draining. Introverts often
              require a period of solitude and quiet in order to “recharge.”
            </Text>
            <View style={styles.row}>
              <Text variant="bodyMedium" style={styles.label}>
                Sources:
              </Text>
              <TraitSource source="genome" />
              <TraitSource source="quiz" />
            </View>
            <View style={styles.row}>
              <Text variant="bodyMedium" style={styles.label}>
                Hormones responsible:
              </Text>
              <HormoneIcon hormone={{ id: "cortisol", imbalance: "high" }} />
              <HormoneIcon hormone={{ id: "testosterone", imbalance: "low" }} />
            </View>
          </Card.Content>
        </Card>
        <Card style={styles.card}>
          <Card.Title
            title="Conscientiousness"
            titleVariant="headlineSmall"
            left={(props) => (
              <Avatar.Icon
                {...props}
                icon={() => (
                  <Icon name="local-library" size={28} style={styles.icon} />
                )}
              />
            )}
          />
          <Card.Content>
            <Text variant="bodyMedium" style={styles.paragraph}>
              Individuals with low Conscientiousness rankings are less able to
              delay gratification, and hence will be more prone to following
              their impulses. While this makes for a lot of fun at parties and
              can be just what is needed in situations when action takes
              precedent to thought, it also means people with low
              Conscientiousness will prove difficult to manage and can often
              find themselves in trouble with authority figures.
            </Text>
            <View style={styles.row}>
              <Text variant="bodyMedium" style={styles.label}>
                Sources:
              </Text>
              <TraitSource source="genome" />
              <TraitSource source="quiz" />
            </View>
            <View style={styles.row}>
              <Text variant="bodyMedium" style={styles.label}>
                Hormones responsible:
              </Text>
              <HormoneIcon hormone={{ id: "cortisol", imbalance: "high" }} />
              <HormoneIcon hormone={{ id: "dopamine", imbalance: "low" }} />
              <HormoneIcon hormone={{ id: "adrenaline", imbalance: "high" }} />
            </View>
          </Card.Content>
        </Card>
        <Card style={styles.card}>
          <Card.Title
            title="Neuroticism"
            titleVariant="headlineSmall"
            left={(props) => (
              <Avatar.Icon
                {...props}
                icon={() => (
                  <FaIcon name="bomb" size={22} style={styles.icon} />
                )}
              />
            )}
          />
          <Card.Content>
            <Text variant="bodyMedium" style={styles.paragraph}>
              Those with high Neuroticism tend to be emotionally reactive, prone
              to intense responses to stimuli that other individuals who are not
              high in Neuroticism wouldn’t tend to react to. These outbursts can
              gradually erode an individual’s ability to think logically, make
              complex decisions with levity, and effectively cope with stress.
              Individuals with high levels of Neuroticism tend to come off as
              negative, exacerbating even the slightest of setbacks and having
              perpetual “bad moods.”
            </Text>
            <View style={styles.row}>
              <Text variant="bodyMedium" style={styles.label}>
                Sources:
              </Text>
              <TraitSource source="genome" />
              <TraitSource source="quiz" />
              <TraitSource source="diary" />
            </View>
            <View style={styles.row}>
              <Text variant="bodyMedium" style={styles.label}>
                Hormones responsible:
              </Text>
              <HormoneIcon hormone={{ id: "cortisol", imbalance: "high" }} />
              <HormoneIcon hormone={{ id: "dopamine", imbalance: "low" }} />
              <HormoneIcon hormone={{ id: "serotonin", imbalance: "low" }} />
              <HormoneIcon hormone={{ id: "adrenaline", imbalance: "high" }} />
            </View>
          </Card.Content>
        </Card>
      </ScrollView>
    </ScreenWrapper>
  );
};

export default Profile;

const getStyles = ({ colors }: { colors: MD3Colors }) => {
  return StyleSheet.create({
    surface: {
      padding: 8,
      height: 200,
      alignItems: "center",
      justifyContent: "center"
    },
    container: {
      flex: 1
    },
    content: {
      padding: 4
    },
    card: {
      margin: 4
    },
    chip: {
      margin: 4
    },
    row: {
      flexDirection: "row",
      flexWrap: "wrap",
      paddingHorizontal: 12
    },
    icon: {
      color: colors.onPrimary
    },
    iconSource: {
      color: colors.onPrimaryContainer
    },
    label: {
      marginBottom: 0,
      color: colors.secondary,
      marginTop: 8
    },
    paragraph: {
      textAlign: "justify",
      marginBottom: 8
    }
  });
};

function useStyles() {
  const { colors } = useTheme();

  // We only want to recompute the stylesheet on changes in color.
  const styles = useMemo(() => getStyles({ colors }), [colors]);

  return styles;
}
