import { useMemo } from "react";
import { Text, Avatar, Card, Chip, useTheme } from "react-native-paper";
import Icon from "react-native-vector-icons/MaterialIcons";
import MCIcon from "react-native-vector-icons/MaterialCommunityIcons";
import FtIcon from "react-native-vector-icons/Fontisto";
import FoIcon from "react-native-vector-icons/Foundation";
import FaIcon from "react-native-vector-icons/FontAwesome5";
import IIcon from "react-native-vector-icons/Ionicons";
import { View, StyleSheet } from "react-native";
import moment from "moment";
import { MD3Colors } from "react-native-paper/src/types";

export type TimelineEvent = {
  eventType: EventType;
  content: any;
  timestamp: Date;
};

type EventType = "psychedelic" | "wellness-recording" | "diary";

type OutputTimelineEvent = {
  eventType: EventType;
  description?: any;
  time?: string;
  lineColor?: string;
  icon?: React.ReactElement;
  title?: string;
};

const getIcon = (eventType: EventType): React.ReactElement => {
  let icon = null;
  const styles = useStyles();
  switch (eventType) {
    case "psychedelic":
      icon = (
        <MCIcon
          name="lightbulb-cfl-spiral"
          style={styles.timelineIcon}
          size={20}
        />
      );
      break;
    case "diary":
      icon = (
        <FoIcon name="book-bookmark" style={styles.timelineIcon} size={20} />
      );
      break;
    case "wellness-recording":
      icon = <FtIcon name="heartbeat" style={styles.timelineIcon} size={20} />;
      break;
  }
  return icon;
};

const getLineColor = (eventType: EventType, colors: MD3Colors): string => {
  let lineColor = null;
  switch (eventType) {
    case "psychedelic":
      lineColor = colors.tertiary;
      break;
    case "diary":
      lineColor = colors.secondary;
      break;
    case "wellness-recording":
      lineColor = colors.onTertiaryContainer;
      break;
  }
  return lineColor;
};

const titleLookup = {
  "wellness-recording": "Wellness recording",
  psychedelic: "Psychedelic session",
  diary: "Diary entry"
};

type DiaryEntry = {
  moodOverall: number;
  stress: number;
  social: number;
  impulsive: number;
  concentration: number;
  alcohol: number;
  text: string;
};

const formatContent = (
  eventType: EventType,
  content: DiaryEntry | any
): any => {
  if (eventType === "diary") {
    const styles = useStyles();
    return (
      <View>
        <View style={styles.row}>
          <View style={styles.col}>
            <Text variant="labelSmall">Overall mood: </Text>
          </View>
          <View style={styles.col}>
            {[...Array(content.moodOverall)].map((x, i) => (
              <FaIcon
                name="smile"
                key={"mood-" + i}
                style={styles.icon}
                size={16}
              />
            ))}
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.col}>
            <Text variant="labelSmall">Stress: </Text>
          </View>
          <View style={styles.col}>
            {[...Array(content.stress)].map((x, i) => (
              <MCIcon
                name="waves"
                key={"stress-" + i}
                style={styles.icon}
                size={16}
              />
            ))}
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.col}>
            <Text variant="labelSmall">Social: </Text>
          </View>
          <View style={styles.col}>
            {[...Array(content.social)].map((x, i) => (
              <IIcon
                name="people"
                key={"stress-" + i}
                style={styles.icon}
                size={16}
              />
            ))}
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.col}>
            <Text variant="labelSmall">Impulsive: </Text>
          </View>
          <View style={styles.col}>
            {[...Array(content.impulsive)].map((x, i) => (
              <FaIcon
                name="dumpster-fire"
                key={"impulsive-" + i}
                style={styles.icon}
                size={16}
              />
            ))}
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.col}>
            <Text variant="labelSmall">Concentration: </Text>
          </View>
          <View style={styles.col}>
            {[...Array(content.stress)].map((x, i) => (
              <FaIcon
                name="brain"
                key={"concentration-" + i}
                style={styles.icon}
                size={16}
              />
            ))}
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.col}>
            <Text variant="labelSmall">Alcohol: </Text>
          </View>
          <View style={styles.col}>
            {[...Array(content.alcohol)].map((x, i) => (
              <IIcon
                name="beer"
                key={"stress-" + i}
                style={styles.icon}
                size={16}
              />
            ))}
          </View>
        </View>
        <Text variant="labelMedium">Text: </Text>
        <Text variant="bodySmall">{content.text}</Text>
      </View>
    );
  } else if (eventType == "wellness-recording") {
    const styles = useStyles();
    return (
      <View>
        <Text variant="bodySmall">Duration: 00:45</Text>
        <Text variant="bodySmall">Wellness reading before: 6</Text>
        <Text variant="bodySmall">Wellness reading after: 2</Text>
        <Text variant="bodyLarge">Change: -2</Text>
      </View>
    );
  }
  return null;
};

const formatEvent = (
  ev: TimelineEvent,
  colors: MD3Colors
): OutputTimelineEvent => {
  const outputEvent: OutputTimelineEvent = {
    eventType: ev.eventType,
    icon: getIcon(ev.eventType),
    time: moment(ev.timestamp).format("MMMM Do YYYY"),
    title: titleLookup[ev.eventType],
    lineColor: getLineColor(ev.eventType, colors),
    description: formatContent(ev.eventType, ev.content)
  };
  return outputEvent;
};

const formattedTimelineData = (
  timelineData: TimelineEvent[],
  colors: MD3Colors
): OutputTimelineEvent[] => {
  return timelineData.map((ev: any) => formatEvent(ev, colors));
};

export default formattedTimelineData;

const getStyles = ({ colors }: { colors: MD3Colors }) => {
  return StyleSheet.create({
    row: {
      flexDirection: "row",
      flexWrap: "nowrap",
      paddingHorizontal: 12
    },
    col: {
      flexDirection: "row",
      flexWrap: "wrap",
      paddingHorizontal: 12
    },
    timelineIcon: {
      color: colors.onPrimaryContainer
    },
    icon: {
      color: colors.onTertiaryContainer
    }
  });
};

function useStyles() {
  const { colors } = useTheme();

  // We only want to recompute the stylesheet on changes in color.
  const styles = useMemo(() => getStyles({ colors }), [colors]);

  return styles;
}
