import Icon from "react-native-vector-icons/MaterialIcons";
import FaIcon from "react-native-vector-icons/FontAwesome5";
import FIcon from "react-native-vector-icons/Fontisto";
import IIcon from "react-native-vector-icons/Ionicons";
import { useMemo } from "react";
import { Chip } from "react-native-paper";
import { StyleSheet } from "react-native";
import { useTheme } from "react-native-paper";
import { MD3Colors } from "react-native-paper/src/types";
import { useNavigation } from "@react-navigation/native";

export type Hormone = {
  id:
    | "oxytocin"
    | "serotonin"
    | "dopamine"
    | "adrenaline"
    | "cortisol"
    | "testosterone"
    | "oestrogen"
    | "adrenaline";
  imbalance: "high" | "low";
};

export const HormoneIcon = ({ hormone }: { hormone: Hormone }) => {
  const styles = useStyles();
  const navigation = useNavigation();
  const icon = () => {
    switch (hormone.id) {
      case "oxytocin":
        return (
          <Icon
            name="volunteer-activism"
            size={16}
            style={styles.hormoneIcon}
          />
        );
      case "serotonin":
        return (
          <Icon name="nature-people" size={16} style={styles.hormoneIcon} />
        );
      case "dopamine":
        return <FIcon name="smiley" size={16} style={styles.hormoneIcon} />;
      case "adrenaline":
        return <FaIcon name="skiing" size={16} style={styles.hormoneIcon} />;
      case "cortisol":
        return (
          <IIcon name="thunderstorm" size={16} style={styles.hormoneIcon} />
        );
      case "testosterone":
        return (
          <Icon name="fitness-center" size={16} style={styles.hormoneIcon} />
        );
      case "oestrogen":
        return <FaIcon name="female" size={16} style={styles.hormoneIcon} />;
      case "adrenaline":
        return (
          <Icon name="electric-bolt" size={16} style={styles.hormoneIcon} />
        );
    }
  };
  return (
    <Chip
      style={styles.hormoneChip}
      icon={icon}
      onPress={() => navigation.navigate("Hormones", { hormone: hormone.id })}
    >
      {hormone.id}
    </Chip>
  );
};

const getStyles = ({ colors }: { colors: MD3Colors }) => {
  return StyleSheet.create({
    hormoneChip: {
      margin: 4,
      backgroundColor: colors.tertiaryContainer
    },
    hormoneIcon: {
      color: colors.onTertiaryContainer
    }
  });
};

function useStyles() {
  const { colors } = useTheme();

  // We only want to recompute the stylesheet on changes in color.
  const styles = useMemo(() => getStyles({ colors }), [colors]);

  return styles;
}
