import React, { useState, useMemo, useEffect } from "react";
import { StyleSheet, View, ScrollView } from "react-native";
import { Button, Appbar, Card, Text, Chip } from "react-native-paper";
import ScreenWrapper from "../ScreenWrapper";
import SelectDropdown from "react-native-select-dropdown";
import hormonesData from "../data/hormones.json";
import Icon from "react-native-vector-icons/MaterialIcons";
import { useTheme } from "react-native-paper";
import { MD3Colors } from "react-native-paper/src/types";
import { HormoneIcon } from "../components/Tags";

type Hormone = {
  id: string;
  title: string;
  description: string;
};

export default function Hormones({ navigation, route }) {
  const [selectedHormone, setSelectedHormone] = useState<Hormone>({
    id: "",
    title: "",
    description: ""
  });
  useEffect(() => {
    const hormoneParam = route.params?.hormone;
    if (hormoneParam) {
      const activeHormone = hormonesData.find((h) => h.id === hormoneParam);
      if (activeHormone) setSelectedHormone(activeHormone);
    }
  }, [route]);
  const styles = useStyles();

  return (
    <ScreenWrapper>
      <View style={styles.container}>
        <SelectDropdown
          defaultButtonText="Select a hormone"
          data={hormonesData}
          onSelect={(selectedItem, index) => {
            setSelectedHormone(selectedItem);
          }}
          buttonTextAfterSelection={(selectedItem, index) => {
            // text represented after item is selected
            // if data array is an array of objects then return selectedItem.property to render after item is selected
            return selectedItem.title;
          }}
          rowTextForSelection={(item, index) => {
            // text represented for each item in dropdown
            // if data array is an array of objects then return item.property to represent item in dropdown
            return item.title;
          }}
          buttonStyle={styles.dropdown1BtnStyle}
          buttonTextStyle={styles.dropdown1BtnTxtStyle}
          renderDropdownIcon={(isOpened) => {
            return isOpened ? (
              <Icon name="expand-less" style={styles.dropdown1BtnTxtStyle} />
            ) : (
              <Icon name="expand-more" style={styles.dropdown1BtnTxtStyle} />
            );
          }}
          dropdownIconPosition={"right"}
          dropdownStyle={styles.dropdown1DropdownStyle}
          rowStyle={styles.dropdown1RowStyle}
          rowTextStyle={styles.dropdown1RowTxtStyle}
        />
      </View>
      <ScrollView style={styles.contentContainer}>
        {selectedHormone.title ? (
          <>
            <Text variant="headlineLarge" style={styles.headline}>
              {selectedHormone.title}
            </Text>
            <Text variant="bodyMedium" style={styles.text}>
              {selectedHormone.description}
            </Text>
          </>
        ) : null}
      </ScrollView>
    </ScreenWrapper>
  );
}

const getStyles = ({ colors }: { colors: MD3Colors }) => {
  return StyleSheet.create({
    chip: {
      margin: 4,
      backgroundColor: colors.tertiaryContainer
    },
    icon: {
      color: colors.onTertiaryContainer
    },
    textbox: {},
    container: {
      padding: 12,
      alignItems: "center",
      justifyContent: "center"
    },
    contentContainer: {
      padding: 12
    },
    row: {
      flexDirection: "column",
      paddingHorizontal: 12,
      paddingBottom: 12,
      rowGap: 12,
      alignItems: "center"
    },
    dropdown1BtnStyle: {
      flex: 1,
      height: 50,
      backgroundColor: colors.secondaryContainer,
      borderRadius: 8,
      borderWidth: 1,
      borderColor: colors.outline,
      width: 300
    },
    dropdown1BtnTxtStyle: {
      color: colors.onBackground,
      textAlign: "left"
    },
    dropdown1DropdownStyle: {
      backgroundColor: colors.secondaryContainer
    },
    dropdown1RowStyle: {
      backgroundColor: colors.secondaryContainer,
      borderBottomColor: colors.outline
    },
    dropdown1RowTxtStyle: {
      color: colors.onBackground,
      textAlign: "left"
    },
    text: {
      marginBottom: 8,
      marginTop: 8,
      textAlign: "justify"
    },
    headline: {},
    col: {
      flexDirection: "row",
      flexWrap: "wrap",
      paddingHorizontal: 12
    }
  });
};

function useStyles() {
  const { colors } = useTheme();

  // We only want to recompute the stylesheet on changes in color.
  const styles = useMemo(() => getStyles({ colors }), [colors]);

  return styles;
}
