import React, { useMemo } from "react";
import { Surface, Text, Avatar, Card, Chip } from "react-native-paper";
import ScreenWrapper from "../ScreenWrapper";
import { StyleSheet, ScrollView, View } from "react-native";

import { useTheme } from "react-native-paper";
import { MD3Colors } from "react-native-paper/src/types";
import formatTimelineData from "../components/TimelineEvent";
import timelineData from "../data/timelineData.json";

import Timeline from "react-native-timeline-flatlist";

const TimelinePage = () => {
  const styles = useStyles();
  const { colors } = useTheme();
  const data = formatTimelineData(timelineData, colors);

  return (
    <ScreenWrapper>
      <Surface style={styles.surface} elevation={4}>
        <Avatar.Text size={80} label="NN" />
        <Text variant="headlineLarge">Nicole’s Timeline</Text>
      </Surface>
      <ScrollView
        style={[styles.container]}
        contentContainerStyle={styles.content}
      >
        <Timeline
          data={data}
          circleSize={30}
          circleColor="rgb(45,156,219)"
          lineColor="rgb(45,156,219)"
          timeContainerStyle={{ minWidth: 52, marginTop: -5 }}
          timeStyle={{
            textAlign: "center",
            backgroundColor: colors.tertiaryContainer,
            color: colors.onTertiaryContainer,
            padding: 5,
            borderRadius: 13,
            maxWidth: 200
          }}
          circleStyle={{ backgroundColor: colors.primaryContainer }}
          innerCircle={"icon"}
          titleStyle={styles.titleStyle}
          detailContainerStyle={{
            marginBottom: 20,
            paddingLeft: 5,
            paddingRight: 5,
            backgroundColor: colors.secondaryContainer,
            borderRadius: 10,
            width: "auto"
          }}
          descriptionStyle={{ color: colors.secondaryContainer }}
          columnFormat="single-column-left"
          options={{
            style: { paddingTop: 5, maxWidth: 500 }
          }}
        />
      </ScrollView>
    </ScreenWrapper>
  );
};

export default TimelinePage;

const getStyles = ({ colors }: { colors: MD3Colors }) => {
  return StyleSheet.create({
    surface: {
      padding: 8,
      height: 200,
      alignItems: "center",
      justifyContent: "center"
    },
    container: {
      flex: 1
    },
    content: {
      padding: 4,
      maxWidth: 500,
      marginLeft: "auto",
      marginRight: "auto"
    },
    card: {
      margin: 4
    },
    chip: {
      margin: 4
    },
    row: {
      flexDirection: "row",
      flexWrap: "wrap",
      paddingHorizontal: 12
    },
    icon: {
      color: colors.onPrimary
    },
    iconSource: {
      color: colors.onPrimaryContainer
    },
    label: {
      marginBottom: 0,
      color: colors.secondary,
      marginTop: 8
    },
    paragraph: {
      textAlign: "justify",
      marginBottom: 8
    },
    titleStyle: {
      color: colors.onSecondaryContainer
    }
  });
};

function useStyles() {
  const { colors } = useTheme();

  // We only want to recompute the stylesheet on changes in color.
  const styles = useMemo(() => getStyles({ colors }), [colors]);

  return styles;
}
