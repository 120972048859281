import {
  MD3LightTheme,
  MD3DarkTheme,
  Provider as PaperProvider,
  configureFonts
} from "react-native-paper";
import { Platform, Appearance, useColorScheme } from "react-native";
import color from "color";
import { tokens } from "./Constants";

const { palette, opacity } = tokens;

const yesevaOne = Platform.select({
  web: "YesevaOne_400Regular, serif",
  ios: "YesevaOne_400Regular",
  default: "serif"
});
const defaultFont = Platform.select({
  web: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  ios: "Helvetica Neue",
  default: "sans-serif"
});

const fontConfig = {
  headlineLarge: {
    fontFamily: yesevaOne,
    fontSize: 32,
    fontWeight: "400",
    letterSpacing: 0,
    lineHeight: 40
  },
  headlineMedium: {
    fontFamily: yesevaOne,
    fontSize: 28,
    fontWeight: "400",
    letterSpacing: 0,
    lineHeight: 36
  },
  headlineSmall: {
    fontFamily: yesevaOne,
    fontSize: 24,
    fontWeight: "400",
    letterSpacing: 0,
    lineHeight: 32
  },
  titleLarge: {
    fontFamily: yesevaOne,
    fontSize: 22,
    fontWeight: "400",
    letterSpacing: 0,
    lineHeight: 28
  },
  titleMedium: {
    fontFamily: yesevaOne,
    fontSize: 16,
    fontWeight: "500",
    letterSpacing: 0.15,
    lineHeight: 24
  },
  titleSmall: {
    fontFamily: yesevaOne,
    fontSize: 14,
    fontWeight: "500",
    letterSpacing: 0.1,
    lineHeight: 20
  },
  labelLarge: {
    fontFamily: defaultFont,
    fontSize: 14,
    fontWeight: "500",
    letterSpacing: 0.1,
    lineHeight: 20
  },
  labelMedium: {
    fontFamily: defaultFont,
    fontSize: 12,
    fontWeight: "500",
    letterSpacing: 0.5,
    lineHeight: 16
  },
  labelSmall: {
    fontFamily: defaultFont,
    fontSize: 11,
    fontWeight: "500",
    letterSpacing: 0.5,
    lineHeight: 16
  },
  bodyLarge: {
    fontFamily: defaultFont,
    fontSize: 18,
    fontWeight: "400",
    letterSpacing: 0.15,
    lineHeight: 24
  },
  bodyMedium: {
    fontFamily: defaultFont,
    fontSize: 16,
    fontWeight: "400",
    letterSpacing: 0.25,
    lineHeight: 20
  },
  bodySmall: {
    fontFamily: defaultFont,
    fontSize: 12,
    fontWeight: "400",
    letterSpacing: 0.4,
    lineHeight: 16
  },
  default: {
    fontFamily: defaultFont
  }
};

export const lightTheme = {
  ...MD3LightTheme,
  roundness: 0,
  colors: {
    primary: palette.primary40,
    primaryContainer: palette.primary90,
    secondary: palette.secondary40,
    secondaryContainer: palette.secondary90,
    tertiary: palette.tertiary40,
    tertiaryContainer: palette.tertiary90,
    surface: palette.neutral99,
    surfaceVariant: palette.neutralVariant90,
    surfaceDisabled: color(palette.neutral10)
      .alpha(opacity.level2)
      .rgb()
      .string(),
    background: palette.neutral99,
    error: palette.error40,
    errorContainer: palette.error90,
    onPrimary: palette.primary100,
    onPrimaryContainer: palette.primary10,
    onSecondary: palette.secondary100,
    onSecondaryContainer: palette.secondary10,
    onTertiary: palette.tertiary100,
    onTertiaryContainer: palette.tertiary10,
    onSurface: palette.neutral10,
    onSurfaceVariant: palette.neutralVariant30,
    onSurfaceDisabled: color(palette.neutral10)
      .alpha(opacity.level4)
      .rgb()
      .string(),
    onError: palette.error100,
    onErrorContainer: palette.error10,
    onBackground: palette.neutral10,
    outline: palette.neutralVariant50,
    outlineVariant: palette.neutralVariant80,
    inverseSurface: palette.neutral20,
    inverseOnSurface: palette.neutral95,
    inversePrimary: palette.primary80,
    shadow: palette.neutral0,
    scrim: palette.neutral0,
    backdrop: color(palette.neutralVariant20).alpha(0.4).rgb().string(),
    elevation: {
      level0: "transparent",
      // Note: Color values with transparency cause RN to transfer shadows to children nodes
      // instead of View component in Surface. Providing solid background fixes the issue.
      // Opaque color values generated with `palette.primary99` used as background
      level1: "rgb(247, 243, 249)", // palette.primary40, alpha 0.05
      level2: "rgb(243, 237, 246)", // palette.primary40, alpha 0.08
      level3: "rgb(238, 232, 244)", // palette.primary40, alpha 0.11
      level4: "rgb(236, 230, 243)", // palette.primary40, alpha 0.12
      level5: "rgb(233, 227, 241)" // palette.primary40, alpha 0.14
    }
  },
  fonts: fontConfig
};

export const darkTheme = {
  ...MD3DarkTheme,
  roundness: 0,
  colors: {
    primary: palette.primary80,
    primaryContainer: palette.primary30,
    secondary: palette.secondary80,
    secondaryContainer: palette.secondary30,
    tertiary: palette.tertiary80,
    tertiaryContainer: palette.tertiary30,
    surface: palette.neutral10,
    surfaceVariant: palette.neutralVariant30,
    surfaceDisabled: color(palette.neutral90)
      .alpha(opacity.level2)
      .rgb()
      .string(),
    background: palette.neutral10,
    error: palette.error80,
    errorContainer: palette.error30,
    onPrimary: palette.primary20,
    onPrimaryContainer: palette.primary90,
    onSecondary: palette.secondary20,
    onSecondaryContainer: palette.secondary90,
    onTertiary: palette.tertiary20,
    onTertiaryContainer: palette.tertiary90,
    onSurface: palette.neutral90,
    onSurfaceVariant: palette.neutralVariant80,
    onSurfaceDisabled: color(palette.neutral90)
      .alpha(opacity.level4)
      .rgb()
      .string(),
    onError: palette.error20,
    onErrorContainer: palette.error80,
    onBackground: palette.neutral90,
    outline: palette.neutralVariant60,
    outlineVariant: palette.neutralVariant30,
    inverseSurface: palette.neutral90,
    inverseOnSurface: palette.neutral20,
    inversePrimary: palette.primary40,
    shadow: palette.neutral0,
    scrim: palette.neutral0,
    backdrop: color(palette.neutralVariant20).alpha(0.4).rgb().string(),
    elevation: {
      level0: "transparent",
      // Note: Color values with transparency cause RN to transfer shadows to children nodes
      // instead of View component in Surface. Providing solid background fixes the issue.
      // Opaque color values generated with `palette.primary80` used as background
      level1: "rgb(37, 35, 42)", // palette.primary80, alpha 0.05
      level2: "rgb(44, 40, 49)", // palette.primary80, alpha 0.08
      level3: "rgb(49, 44, 56)", // palette.primary80, alpha 0.11
      level4: "rgb(51, 46, 58)", // palette.primary80, alpha 0.12
      level5: "rgb(52, 49, 63)" // palette.primary80, alpha 0.14
    }
  },
  fonts: configureFonts({
    config: fontConfig
  })
};
