import "react-native-gesture-handler";
import React from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import Home from "./screens/Home";
import WebView from "./screens/WebView";
import Profile from "./screens/Profile";
import DiaryEntry from "./screens/DiaryEntry";
import Disorders from "./screens/Disorders";
import Hormones from "./screens/Hormones";
import Psychedelic from "./screens/Psychedelic";
import { Appbar, Menu } from "react-native-paper";
import Timeline from "./screens/Timeline";

const Stack = createStackNavigator();

function CustomNavigationBar({ navigation, back }) {
  const [visible, setVisible] = React.useState(false);
  const openMenu = () => setVisible(true);
  const closeMenu = () => setVisible(false);

  return (
    <Appbar.Header>
      {back ? <Appbar.BackAction onPress={navigation.goBack} /> : null}
      <Appbar.Content title="The Other Big O" />
      {!back ? (
        <Menu
          visible={visible}
          onDismiss={closeMenu}
          anchor={<Appbar.Action icon="menu" onPress={openMenu} />}
        >
          <Menu.Item
            onPress={() => {
              navigation.navigate("Home");
            }}
            title="Home"
          />
          <Menu.Item
            onPress={() => {
              navigation.navigate("Profile");
            }}
            title="Profile"
          />
          <Menu.Item
            onPress={() => {
              navigation.navigate("DiaryEntry");
            }}
            title="Diary Entry"
          />
          <Menu.Item
            onPress={() => {
              navigation.navigate("Timeline");
            }}
            title="WellnessTimeline"
          />
          <Menu.Item
            onPress={() => {
              navigation.navigate("Disorders");
            }}
            title="Disorders"
          />
          <Menu.Item
            onPress={() => {
              navigation.navigate("Hormones");
            }}
            title="Hormones"
          />
          <Menu.Item
            onPress={() => {
              navigation.navigate("Psychedelic");
            }}
            title="Psychedelic"
          />
          <Menu.Item
            onPress={() => {
              navigation.navigate("WebView");
            }}
            title="Content"
          />
        </Menu>
      ) : null}
    </Appbar.Header>
  );
}
export default function App() {
  return (
    <NavigationContainer>
      <Stack.Navigator
        initialRouteName="Home"
        screenOptions={{
          header: (props) => <CustomNavigationBar {...props} />
        }}
      >
        <Stack.Screen name="Home" component={Home} />
        <Stack.Screen name="Profile" component={Profile} />
        <Stack.Screen name="DiaryEntry" component={DiaryEntry} />
        <Stack.Screen name="Timeline" component={Timeline} />
        <Stack.Screen name="Disorders" component={Disorders} />
        <Stack.Screen name="Hormones" component={Hormones} />
        <Stack.Screen name="Psychedelic" component={Psychedelic} />
        <Stack.Screen name="WebView" component={WebView} />
      </Stack.Navigator>
    </NavigationContainer>
  );
}
