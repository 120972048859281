import React, { useMemo, useState } from "react";
import {
  Dimensions,
  Platform,
  Pressable,
  StyleSheet,
  View
} from "react-native";
import { Text, useTheme } from "react-native-paper";
import ScreenWrapper from "../ScreenWrapper";
import Image from "../components/scalable-image";
import { MD3Colors } from "react-native-paper/src/types";

export default function Psychedelic() {
  const [playAnimation, setPlayAnimation] = useState(false);
  const styles = useStyles();
  const { colors } = useTheme();
  return (
    <ScreenWrapper>
      <View style={styles.container}>
        <Text variant="titleLarge">Psychedelic mode</Text>
        <Pressable
          onPress={() => {
            console.log("pressed");
            setPlayAnimation(!playAnimation);
          }}
        >
          <Text>Press the image to start and stop the animation</Text>
          <View style={!playAnimation ? styles.gifVisible : styles.gifHidden}>
            <Image
              width={Dimensions.get("window").width - 24}
              source={require("../../assets/psychedelic.gif")}
            />
          </View>
        </Pressable>
      </View>
    </ScreenWrapper>
  );
}

const getStyles = ({ colors }: { colors: MD3Colors }) => {
  return StyleSheet.create({
    gifVisible: {
      opacity: 100,
      zIndex: 1
    },
    gifHidden: {
      opacity: 0,
      zIndex: 1
    },
    container: {
      padding: 12
    }
  });
};

function useStyles() {
  const { colors } = useTheme();

  // We only want to recompute the stylesheet on changes in color.
  const styles = useMemo(() => getStyles({ colors }), [colors]);

  return styles;
}
