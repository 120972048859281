import React from "react";
import { StyleSheet, View } from "react-native";
import { Button, Appbar, Card, Text, Avatar } from "react-native-paper";
import ScreenWrapper from "../ScreenWrapper";

export default function Home({ navigation }) {
  return (
    <ScreenWrapper>
      <View style={styles.container}>
        <Text style={styles.text}>What would you like to do?</Text>
        <View style={styles.row}>
          <Button
            mode="contained-tonal"
            style={styles.button}
            onPress={() => navigation.navigate("DiaryEntry")}
          >
            Make a Diary entry
          </Button>
          <Button
            mode="contained-tonal"
            style={styles.button}
            onPress={() => navigation.navigate("Timeline")}
          >
            View your Wellness Timeline
          </Button>
          <Button
            mode="contained-tonal"
            style={styles.button}
            onPress={() => navigation.navigate("Profile")}
          >
            Go to Profile
          </Button>
          <Button
            mode="contained-tonal"
            style={styles.button}
            onPress={() => navigation.navigate("Hormones")}
          >
            Learn about hormones
          </Button>
          <Button
            mode="contained-tonal"
            style={styles.button}
            onPress={() => navigation.navigate("Disorders")}
          >
            Learn about disorders
          </Button>
          <Button
            mode="contained-tonal"
            style={styles.button}
            onPress={() => navigation.navigate("Psychedelic")}
          >
            Watch a psychedelic animation
          </Button>
        </View>
      </View>
    </ScreenWrapper>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center"
  },
  row: {
    flexDirection: "column",
    paddingHorizontal: 12,
    paddingBottom: 12,
    rowGap: 12,
    alignItems: "center"
  },
  button: {
    width: 300
  },
  text: {
    margin: 12
  }
});
